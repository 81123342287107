<template>
  <v-dialog :width="$vuetify.breakpoint.xs ? '100%' : '35%'" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-card
        @click="confirm"
        :disabled="disabled"
        style="border: 1px solid var(--v-primary-base)"
      >
        <v-card-title
          class="py-6 justify-center  text-decoration-none text-center
                        d-block
                        text-uppercase"
          style="font-size: 18px !important; text-decoration: none !important"
        >
          {{ $t("appointments.appointment_completed") }}
        </v-card-title>
      </v-card>
    </template>
    <v-card id="ConfirmForm">
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
      >
        {{ $t("appointments.appointment_completed") }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="confirm-form"
        @submit.prevent="validateForm('confirm-form')"
      >
        <v-card-text>
          <v-row v-if="!appointment.design" justify="center">
            <v-col class="py-0 pb-0" cols="12" md="8">
              <label v-text="$t('designs.name')"></label>
              <v-text-field
                outlined
                dense
                v-model="form.name"
                data-vv-validate-on="blur"
                v-validate="'required|min:2'"
                :error-messages="errors.collect('design-form.name')"
                color="secondary"
                hide-details
                data-vv-name="name"
              ></v-text-field>
            </v-col>

            <v-col class="py-0 pb-0" cols="12" md="8">
              <label v-text="$t('designs.size')"></label>
              <v-select
                :items="token_payments"
                v-model="form.size"
                outlined
                hide-details
                dense
                item-text="size"
                item-value="size"
              ></v-select>
            </v-col>
            <v-col class="py-0 pt-0 pb-0" cols="12" md="8">
              <label v-text="$t('appointments.price_desing')"></label>
              <number-input-with-separators
                :value="form"
                valueKey="price"
                ref="money"
                outlined
                hide-details
                dense
                rules="required"
                type="number"
                v-on:keypress="isNumber($event)"
                color="secondary"
              ></number-input-with-separators>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="8" class="pt-0 pb-0">
              <v-checkbox
                off-icon="mdi-circle-outline"
                on-icon="mdi-check-circle"
                v-model="form.token_payment"
              >
                <template v-slot:label="">
                  <div class="mt-1">¿Se ha hecho paga y señal antes?</div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" md="8" class="pt-0 pb-0">
              <label v-text="$tc('expenses.commissions')" />
              <v-select
                clearable
                v-model="form.commissions"
                outlined
                dense
                hide-details
                :items="commissions"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer
          ><v-btn
            outlined
            style="height: 25px"
            @click="
              dialog = false;
              files = [];
            "
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            elevation="0"
            type="submit"
            >{{ $t("confirm") }}</v-btn
          >
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ConfirmAppointmentDialog",
  props: ["appointment", "block", "disabled"],

  components: {
    NumberInputWithSeparators: () =>
      import("@/components/ui/NumberInputWithSeparators"),
  },
  data() {
    return {
      dialog: false,
      token_payments: [],
      form: {},
      commissions: [
        {
          text: this.$t("expenses.commissions_studio"),
          value: "studio",
        },
        {
          text: this.$t("expenses.commissions_tattooer"),
          value: "tattooer",
        },
      ],
    };
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.fetch();
          this.form.commissions = this.appointment.from;
        }
      },
      deep: true,
    },
  },
  methods: {
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    },
    ...mapActions("tattooers", ["getTokenPayments"]),
    fetch() {
      this.getTokenPayments(this.appointment.tattooer_id).then((data) => {
        this.token_payments = data;
      });
    },
    confirm() {
      this.$confirm(this.$t("confim_cita")).then(() => {
        this.confirmAppointment({
          appointment_id: this.appointment.id,
          formData: this.form,
        }).then(() => {
          this.appointment.status = "done";
          this.$emit("update");
        });
      });
    },
    ...mapActions("appointments", ["confirmAppointment"]),
    async validateForm(scope) {
      this.errors.clear();
      let result = await this.$validator.validateAll(scope);
      let money_val = await this.$refs.money.validate();

      if (result && money_val) {
        //console.log("VALIDADO");
        this.$confirm(this.$t("confim_cita")).then(() => {
          this.confirmAppointment({
            appointment_id: this.appointment.id,
            formData: this.form,
          }).then(() => {
            /*this.appointment.status = "done";
            this.$emit("update");*/
            console.log(GO);
            this.$router.push({ name: "appointmentList" });
          });
        });
      }
    },
  },
};
</script>

<style lang="sass">
#ConfirmForm
  .theme--dark.v-icon
    color: var(--v-primary-base)

  label
    display: block
    height: 25px
    color: var(--v-primary-base) !important
</style>
